import { React, useState, useRef } from "react";
import { useEffect } from "react";
import "./game.css";
import {ReactComponent as Bag} from "../../img/bag.svg";
import { ReactComponent as Clock } from "../../img/Clock.svg";
import { ReactComponent as LogoMark } from "../../img/LogoMark.svg";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import GameEnd from "../../components/game-end/GameEnd";
import GameLoading from "../../components/game-loading/GameLoading";
import axios from "axios";

const GamePc = () => {
  const dispatch = useDispatch();
  const passes = useSelector((state) => state.currentUser.passes);
  const telegramId = useSelector(state => state.currentUser.telegramId)
  const navigate = useNavigate();

    const minusPass = async () => {
      try {
        await axios.put(`https://niseapp.lol/api/user/change-pass/${telegramId}`, {
          newPassesBalance: passes - 1
        });
        dispatch({
          type: "change-passes",
          payload: {
            passes: passes - 1
          }
        })
      } catch (error) {
        console.log(error)
        navigate('/')
      }
    }

    useEffect(() => {
    if(passes <= 0) {
      navigate('/')
    }else {
      minusPass()
    }
  }, [])

  useEffect(() => {
    const tg = window.Telegram.WebApp;
    tg.BackButton.show();
    tg.BackButton.onClick(() => {
      tg.BackButton.hide();
      navigate("/");
    });
    return () => {
      tg.BackButton.hide();
    };
  }, []);

  const [position, setPosition] = useState({
    top: window.innerHeight - 70,
    left: null,
  });
  const [loading, setLoading] = useState(true);
  const [isDragging, setIsDragging] = useState(false);
  const draggableRef = useRef(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [flowers, setFlowers] = useState([]);
  const [balance, setBalance] = useState(0);
  const [seconds, setSeconds] = useState(30);
  const [isGameStarted, setIsGameStarted] = useState(false); // Флаг для отслеживания начала игры

  // Запуск таймера только после начала игры
  useEffect(() => {
    if (isGameStarted && seconds > 0) {
      const interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [seconds, isGameStarted]);

  // Создание цветов только после начала игры
  useEffect(() => {
    if (!isGameStarted) return;

    const createFlower = () => {
      const newFlower = {
        id: Date.now(),
        left: Math.round(Math.random() * (screenWidth - 42)),
        top: -10,
        transform: Math.round(Math.random() * 20 - 10),
      };
      setFlowers((prevFlowers) => [...prevFlowers, newFlower]);

      setTimeout(() => {
        setFlowers((prevFlowers) =>
          prevFlowers.filter((flower) => flower.id !== newFlower.id)
        );
      }, 9000);
    };

    const interval = setInterval(createFlower, 400);
    return () => clearInterval(interval);
  }, [isGameStarted, screenWidth]);

  useEffect(() => {
    if (!isGameStarted) return;

    const interval = setInterval(() => {
      setFlowers((prevFlowers) =>
        prevFlowers.map((flower) => ({
          ...flower,
          top: flower.top + 15,
        }))
      );
    }, 50);

    return () => clearInterval(interval);
  }, [isGameStarted]);

  useEffect(() => {
    flowers.forEach((el) => {
      if (
        el.left >= position.left - 15 &&
        el.left <= position.left + 70 &&
        el.top - 130 >= position.top &&
        el.top < position.top + 150
      ) {
        if (seconds > 0) {
          setFlowers((flowers) => flowers.filter((fl) => fl.id !== el.id));
          setBalance(balance + 1);
        }
      }
    });
  }, [position, flowers]);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setIsGameStarted(true); // Начать игру при первом взаимодействии с корзиной

    const shiftX =
      e.clientX - draggableRef.current.getBoundingClientRect().left;
    const shiftY = e.clientY - draggableRef.current.getBoundingClientRect().top;

    const moveAt = (pageX, pageY) => {
      setPosition({
        left: pageX - shiftX,
        top: pageY - shiftY,
      });
    };

    const onMouseMove = (event) => {
      moveAt(event.pageX, event.pageY);
    };

    document.addEventListener("mousemove", onMouseMove);

    document.addEventListener(
      "mouseup",
      () => {
        document.removeEventListener("mousemove", onMouseMove);
        setIsDragging(false);
        setPosition((prevPosition) => ({
          left: prevPosition.left,
          top: screenHeight - 70,
        }));
      },
      { once: true }
    );
  };

  useEffect(() => {
    const loadImages = () => {
      const images = [
        { src: require("../../img/bag.svg"), img: new Image() },
        { src: require("../../img/bag_1.svg"), img: new Image() },
        { src: require("../../img/bag_2.svg"), img: new Image() },
        { src: require("../../img/bag_3.svg"), img: new Image() },
      ];

      images.forEach(({ src, img }) => {
        img.src = src;
      });
    };
    loadImages();
    setTimeout(() => {
      setLoading(false);
    }, 1800);
  }, []);

  return loading ? (
    <GameLoading />
  ) : (
    <div className="game">
      {seconds <= 0 ? (
        <GameEnd balance={balance} restart={() => {
          setSeconds(30)
          setIsGameStarted(false)
          setBalance(0)
          setFlowers([])
          setPosition({top: window.innerHeight - 70, left: null })
          minusPass()
        }}/>
      ) : (
        <>
          <div className="li" style={{ zIndex: '99999999' }}></div>
          <div className="gm_pn">
            <div>
              <Clock />
              <span>
                00:{seconds < 10 && "0"}
                {seconds}
              </span>
            </div>
            <div>
              <LogoMark />
              <span>{balance}</span>
            </div>
          </div>
          <div className="game_panel">
          <div className="catch-text" style={isGameStarted ? {opacity: "0%"} : null}>Catch buds with a basket</div>
          {flowers.map((flower) => (
              <div
                key={flower.id}
                className="cn pc"
                style={{
                  left: `${flower.left}px`,
                  top: `${flower.top}px`,
                  transform: `rotate(${flower.transform}deg)`,
                  transition: "all ease 0.5s",
                }}
              />
            ))}
            <div
              className="bag"
              onMouseDown={handleMouseDown}
              ref={draggableRef}
              style={{
                width: "100px", height: "70px" ,
                position: "absolute",
                cursor: isDragging ? "grabbing" : "grab",
                top:
                !isDragging ? position.top =  screenHeight - 80:
                  +position.top < 50
                    ? (position.top = 50)
                    : position.top >= screenHeight - 80
                    ? (position.top = screenHeight - 80)
                    : position.top,
                left:
                position.left === null ? "auto" :
                  +position.left < 0
                    ? 0
                    : +position.left < +screenWidth - 100
                    ? position.left
                    : +screenWidth - 100,
                transition: isDragging ? "all ease" : "all 1s ease",
              }}
            >
              <Bag style={{ width: "100px", height: "70px" }} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default GamePc;