import React, { useEffect, useState } from "react";
import "./box.css";
import {ReactComponent as Boxx} from "../../img/boxx.svg";
import box from "../../img/chest.gif"
import li from "../../img/light.png";
import { useSelector } from "react-redux";

const Box = ({onOpenBox}) => {

  const [showAnimation, setShowAnimation] = useState(false)
  const keys = useSelector(state => state.currentUser.keys)

  function isAndroid() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    
    return /android/i.test(userAgent)
}

  return (
    <div className="box">
      <div className="box-bg">
      {
          showAnimation ? <Boxx style={{animation: "box-anim 0.9s alternate", transition: "all ease", transform: "scale(1)"}}/> : <Boxx /> 
            }
      </div>
      <button onClick={() => {
        if(keys >= 1) {
          setShowAnimation(true)
          setTimeout(() => {
            onOpenBox()
          }, 1200)
          const tim = setTimeout(() => {
            onOpenBox()
            setShowAnimation(false)
          }, 2000);
        }else {
          onOpenBox()
        }
        }}>
        Open box <img src={li} />
      </button>
    </div>
  );
};

export default Box;
