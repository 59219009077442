import React from 'react'
import './user-tickets.css'
import { ReactComponent as Coin } from "../../img/Items card/Icon.svg";
import { useSelector } from "react-redux";

const UserTickets = ({onTickets}) => {
  const passes = useSelector(state => state.currentUser.passes)

  return (
    <div className="user-tickets" onClick={onTickets}>
      <Coin />
      <span>{passes}</span>
    </div>
  )
}

export default UserTickets