import React from 'react'
import "./game-loading.css"
import Spinner from '../spinner/Spinner'

const GameLoading = () => {
  return (
    <div className='game-loading'>
        <div style={{width: "100%", display: 'flex', justifyContent: 'center', marginTop: '25px'}}>
            <div class="loader-sp" style={{width: "80px"}}></div>
        </div>
    </div>
  )
}

export default GameLoading