import React from "react";
import "./blocks.css";
import { ReactComponent as Key } from "../../img/k1.svg";
import { ReactComponent as Logo } from "../../img/l1.svg";

const Blocks = () => {
  return (
    <div className="blocks_">
      <div className="bll">
        <p>Not premium user</p>
        <div>
          <Logo />
          <span>+500 NISE</span>
        </div>
        <div>
          <Key />
          <span>+0.1 KEY</span>
        </div>
      </div>
      <div className="bll">
        <p>Premium user</p>
        <div>
          <Logo />
          <span>+1000 NISE</span>
        </div>
        <div>
          <Key />
          <span>+0.2 KEY</span>
        </div>
      </div>
    </div>
  );
};

export default Blocks;
