import {createStore} from "redux";

const store = createStore((state, action) => {
    switch(action.type) {
        case "edit-current-user":
            return {
                ...state,
                currentUser: {
                    passes: action.payload.passes,
                    keys: action.payload.keys,
                    balance: action.payload.balance,
                    name: action.payload.name,
                    rank: action.payload.rank,
                    rankType: action.payload.rankType,
                    upgrades: action.payload.upgrades,
                    tasks: action.payload.tasks,
                    telegramId: action.payload.telegramId,
                    id: action.payload.id,
                    passesStage: action.payload.passesStage,
                    keysStage: action.payload.keysStage,
                    startFarm: action.payload.startFarm,
                    passesFarm: action.payload.passesFarm
                }
            }
            break
        case "change-keys":
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    keys: action.payload.keys
                }
            }
            break
        case "change-passes":
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    passes: action.payload.passes
                }
            }
            break
        case "change-balance":
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    balance: action.payload.balance
                }
            }
        case "change-tasks":
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    tasks: action.payload.tasks
                }
            }
        case "change-upgrades":
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    upgrades: action.payload.upgrades
                }
            }
        case "change-passes-stage":
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    passesStage: action.payload.passesStage
                }
            }
        case "change-keys-stage":
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    keysStage: action.payload.keysStage
                }
            }
        case "change-start-farm":
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    startFarm: action.payload.startFarm
                }
            }
        case "change-passes-farm":
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    passesFarm: action.payload.passesFarm
                }
            }
        case "change-rank":
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    rank: action.payload.rank
                }
            }
        case "change-rank-type":
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    rankType: action.payload.rankType
                }
            }
    }

    return state;
}, {
    currentUser: {
        name: "",
        telegramId: null,
        passes: 0,
        id: null,
        keys: 0,
        balance: 0,
        rank: 1,
        rankType: 1,
        upgrades: [],
        tasks: [],
        passesStage: 1,
        keysStage: 1,
        startFarm: null,
        passesFarm: 3
    },
})

export default store