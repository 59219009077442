import React, { useState } from "react";
import "./alert.css";
import { ReactComponent as Warning } from "../../img/Info.svg";
import { ReactComponent as Success } from "../../img/Success.svg";

const Alert = ({ type, text }) => {
  const [goBack, setGoBack] = useState(false);

  setTimeout(() => {
    if (!goBack) {
      setGoBack(true);
    }
  }, "2700");

  return (
    <div
      className="alert"
      style={
        goBack
          ? { animation: "go_back 1s", top: "-250px" }
          : { animation: "alert_animation 0.5s", top: "15px" }
      }
      onClick={() => setGoBack(true)}
    >
      {type === "error" ? <Warning /> : <Success />}
      <span>{text}</span>
    </div>
  );
};

export default Alert;
