import React, { useEffect, useState } from "react";
import "./start-farm.css";
import { ReactComponent as Flw } from "../../img/img-game.svg";
import { ReactComponent as Play } from "../../img/Play.svg";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Coin } from "../../img/Items card/Icon.svg";
import axios from "axios";

const StartFarm = ({onShowError}) => {
  const dispatch = useDispatch();
  const passes = useSelector(state => state.currentUser.passes);
  const startFarm = useSelector(state => state.currentUser.startFarm);
  const telegramId = useSelector(state => state.currentUser.telegramId);
  const passesFarm = useSelector(state => state.currentUser.passesFarm)
  const navigate = useNavigate();

  const [timeLeft, setTimeLeft] = useState(4 * 60 * 60 * 1000);

  useEffect(() => {
    if (startFarm != null) {
      let startTime = Number(startFarm);
      const now = Date.now();
      const timePassed = now - startTime;
      const initialRemainingTime = (4 * 60 * 60 * 1000) - timePassed;
      
      setTimeLeft(initialRemainingTime); // Устанавливаем начальное значение времени
  
      const timerInterval = setInterval(() => {
        const now = Date.now();
        const timePassed = now - startTime;
        const remainingTime = (4 * 60 * 60 * 1000) - timePassed;
  
        if (remainingTime <= 0) {
          setTimeLeft(0);
          clearTImer();
          clearInterval(timerInterval);
        } else {
          setTimeLeft(remainingTime);
        }
      }, 1000);
  
      return () => clearInterval(timerInterval);
    }
  }, [startFarm]);

  const handlePlayClick = () => {
    if (passes === 0) {
      onShowError(); 
    } else {
      navigate('/game'); 
    }
  };

  const clearTImer = async () => {
    try {
      const {data} = await axios.put(`https://niseapp.lol/api/user/clear-timer/${telegramId}`);
      if(data.success) {
        await axios.put(`https://niseapp.lol/api/user/change-pass/${telegramId}`, {
          newPassesBalance: passes + passesFarm
        })
        dispatch({
          type: "change-passes",
          payload: {
            passes: passes + passesFarm
          }
        })
        dispatch({
          type: "change-start-farm",
          payload: {
            startFarm: null
          }
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const startFarming = async () => {
    const now = Date.now();
    try {
      const { data } = await axios.put(`https://niseapp.lol/api/user/create-timer/${telegramId}`, {
        currentTime: now
      });
      if(data.success) {
        dispatch({
          type: "change-start-farm",
          payload: {
            startFarm: now
          }
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const formatTime = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `0${hours}:${minutes < 10 ? '0' : ""}${minutes}:${seconds < 10 ? '0' : ""}${seconds}`;
  };

  return (
    <div className="start_farm">
      <div className="start_play">
        <div>
          <div className="fw">
            <Flw />
          </div>
          <div className="flw_texts">
            <p>Flower picker</p>
            <span>Cost of playing 1 pass</span>
          </div>  
        </div>
          <button className="play_button" onClick={handlePlayClick}>
            <Play />
            Play
          </button>
      </div>
      {startFarm === null ? <button onClick={startFarming}>Start Farm</button> : (
        <div className="farming">
          <div className="frming">
            <p>Farming</p>
            <Coin />
            <p>{passesFarm}</p>
          </div>
          {Math.floor(Math.floor(timeLeft / 1000) / 3600) != 4 && <p className="format_time">{formatTime(timeLeft)}</p>}
        </div>
        )}
    </div>
  );
};

export default StartFarm;
