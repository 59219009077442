import React from "react";
import "./user-balance.css";
import { ReactComponent as Coin } from "../../img/Logo_mark.svg";
import { useSelector } from "react-redux";

const UserBalance = () => {
  const balance = useSelector(state => state.currentUser.balance)

  return (
    <div className="user-balance">
      <Coin style={{height: "24px", width: "24px"}}/>
      <span>{balance.toLocaleString()}</span>
    </div>
  );
};

export default UserBalance;
