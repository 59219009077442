import React, { useState } from "react";
import "./items.css";
import { ReactComponent as Key } from "../../img/List Item/Left/Items card/Key.svg";
import { ReactComponent as Tick } from "../../img/List Item/Left/Items card/Jetton.svg";
import { ReactComponent as Arw } from "../../img/ar.svg";
import { useSelector } from "react-redux";

const Items = ({ open1, open2,  onShowError }) => {

  const balance = useSelector(state => state.currentUser.balance);
  const passesStage = useSelector(state => state.currentUser.passesStage);
  const keysStage = useSelector(state => state.currentUser.keysStage);

  const [passesPrices, setPassesPrices] = useState([
    {stage: 1, price: 500},
    {stage: 2, price: 1000},
    {stage: 3, price: 2000},
    {stage: 4, price: 3000},
    {stage: 5, price: 5000},
    {stage: 6, price: 6000},
    {stage: 7, price: 8000},
    {stage: 8, price: 9000},
    {stage: 9, price: 10000},
    {stage: 10, price: 12000}
  ]);
  const [keysPrices, setKeysPrices] = useState([
    {stage: 1, price: 300},
    {stage: 2, price: 600},
    {stage: 3, price: 800}, 
    {stage: 4, price: 1000},
    {stage: 5, price: 1200},
    {stage: 6, price: 1500},
    {stage: 7, price: 1800},
    {stage: 8, price: 2200},
    {stage: 9, price: 2700},
    {stage: 10, price: 3300},
    {stage: 11, price: 4000},
    {stage: 12, price: 4800},
    {stage: 13, price: 5500},
    {stage: 14, price: 7000},
    {stage: 15, price: 8500},
    {stage: 16, price: 10000},
    {stage: 17, price: 12500},
    {stage: 18, price: 15000},
    {stage: 19, price: 18000},
    {stage: 20, price: 22000},
    {stage: 21, price: 25000},
    {stage: 22, price: 35000},
    {stage: 23, price: 40000},
    {stage: 24, price: 45000},
    {stage: 25, price: 50000},
    {stage: 26, price: 55000},
    {stage: 27, price: 65000},
    {stage: 28, price: 70000},
    {stage: 29, price: 80000},
    {stage: 30, price: 100000}
  ])

  const passPrice = passesPrices.find(priceObj => priceObj.stage == passesStage)?.price;
  const keyPrice = keysPrices.find(priceObj => priceObj.stage == keysStage)?.price;

  return (  
    <div className="items_pg" style={{paddingBottom: '0px'}}>
      <div className="play" onClick={(keysStage <= 30) ?( balance >= keyPrice ? () => open1(keyPrice) :  onShowError) : null}>
        <div>
          <div className="fw">
            <Key />
          </div>
          <div className="flw_texts">
            <p>Buy 1 key</p>
            <span>The cost is {keyPrice} $NISE</span>
          </div>
        </div>
        {keysStage > 30 ? <div className="done-div">Max</div> : <Arw />}
      </div>
      <div className="play" onClick={(passesStage <= 10) ? (balance >= passPrice ? () => open2(passPrice) : onShowError) : null}>
        <div>
          <div className="fw">
            <Tick />
          </div>
          <div className="flw_texts">
            <p>Add 1 pass per farm</p>
            <span>The cost is {passPrice} $NISE</span>
            
          </div>
        </div>
        {passesStage > 10 ? <div className="done-div">Max</div> : <Arw />}
      </div>
    </div>
  );
};

export default Items;
