import { useState, useEffect } from "react";
import NavBar from "./components/navbar/NavBar";
import Home from "./pages/home/Home";
import Loading from "./pages/loading/Loading";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Shop from "./pages/shop/Shop";
import Tasks from "./pages/tasks/Tasks";
import Invite from "./pages/invite/Invite";
import Game from "./pages/game/Game";
import Alert from "./components/alert/Alert";
import UAParser from "ua-parser-js";
import Turn from "./pages/turn/Turn";
import { useDispatch } from "react-redux";
import axios from "axios"
import GamePc from "./pages/game/GamePc";

function App() {
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(false);
  const [showErrorKeys, setShowErrorKeys] = useState(false);
  const [showErrorTickets, setShowErrorTickets] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showBalanceAlert, setShowBalanceAlert] = useState(false)
  const [showPaymentAlert, setShowPaymentAlert] = useState(false)
  const [showNavbar, setShowNavbar] = useState(true);
  const [showLoading, setShowLoading] = useState(true);
  const [showAlertInvite, setShowAlertInvite] = useState(false)
  const [font, setFont] = useState(null);
  const tg = window.Telegram.WebApp;
  tg.ready();
  //const [userName,setUserName] = useState("No Name")
  //const [telegramId, setTelegramId] = useState(1)
  const [userName,setUserName] = useState(tg.initDataUnsafe.user.first_name)
  const [telegramId, setTelegramId] = useState(tg.initDataUnsafe.user.id)

  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      const tg = window.Telegram.WebApp;
      tg.ready();

      tg.setBackgroundColor("#1F252A");
      tg.disableVerticalSwipes();
      tg.setHeaderColor("#1F252A");
      tg.expand();
      tg.themeParams.secondary_bg_color = "#FF0000";
    }
    const parser = new UAParser();
    const result = parser.getResult();
    if (result.os.name == "Windows" || result.os.name == "Linux") {
      setFont({ fontFamily: '"Inter", sans-serif', backgroundImage: "none" });
    }else {
      setIsMobile(true)
    }
  }, []);

  useEffect(() => {

    const getUserData = async () => {
      try {
        const { data } = await axios.get(`https://niseapp.lol/api/user/get-one/${telegramId}`);
        if(data.success) {
          dispatch({
            type: "edit-current-user",
            payload: {
              name: data.username,
              keys: data.keys,
              passes: data.passes,
              balance: data.balance,
              rank: data.rank,
              rankType: data.rankType,
              upgrades: data.Upgrades,
              tasks: data.Tasks,
              telegramId: data.telegramId,
              keysStage: data.keysStage,
              passesStage: data.passesStage,
              id: data.id,
              startFarm: data.startFarm,
              passesFarm: data.passesPerFarm
            }
          })
        }else {
          try {
            const queryParams = new URLSearchParams(window.location.search);
            const paramValue = queryParams.get("code");
            const { data } = await axios.post(`https://niseapp.lol/api/user/create`, {
              username: userName,
              telegramId,
              referral: +paramValue,
              isPremium: window.Telegram.WebApp.initDataUnsafe.user.is_premium
            });
            if(data.success) {
              dispatch({
                type: "edit-current-user",
                payload: {
                  name: data.username,
                  keys: data.keys,
                  passes: data.passes,
                  balance: data.balance,
                  rank: data.rank,
                  rankType: data.rankType,
                  upgrades: data.Upgrades,
                  tasks: data.Tasks,
                  telegramId: data.telegramId,
                  keysStage: data.keysStage,
                  passesStage: data.passesStage,
                  id: data.id,
                  startFarm: data.startFarm,
                  passesFarm: data.passesPerFarm
                }
              })
            }
          } catch (error) {
            console.log(error);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }

    getUserData();

    setTimeout(() => {
      const interval = setInterval(() => {
        if (document.readyState == "complete") {
          setShowLoading(false)
          clearInterval(interval);
        }
      }, 250)
      return () => {
        clearInterval(interval);
      };
    }, "3000");
  }, []);
  return (
    <>
      <Turn />
      <div
        className="App"
        style={
          showLoading
            ? { minHeight: "auto", overflow: "hidden"}
            : font
        }
      >
        {showErrorKeys && <Alert type={"error"} text={"Not enough keys"} />}
        {showErrorTickets && (
          <Alert type={"error"} text={"Not enough passes"} />
        )}
        {showAlert && (
          <Alert type={"success"} text={"The link has been copied"} />
        )}
        {showBalanceAlert && (
          <Alert type={"success"} text={`+500 $NISE, +0.1 Key`} />
        )}
        {showAlertInvite && (
          <Alert type={"success"}  text={`+1.500 $NISE, +0.5 Key`} />
        )
        }
        {showPaymentAlert && (
            <Alert type={"success"} text={`Purchased successfully`} />
          )}
        <div className="li"></div>
        {showLoading ? (
          <Loading />
        ) : (
          <>
            <Router>
              <div
                className="data"
                // style={!showNavbar ? { overflowY: "hidden" } : null}
              >
                <Routes>
                  <Route
                    path="/"
                    element={
                      <Home
                        onHandleNavbar={() => setShowNavbar(!showNavbar)}
                        error={showErrorKeys || showErrorTickets}
                        onHandleTickets={(e) => setShowErrorTickets(e)}
                        onHandleKeys={(e) => setShowErrorKeys(e)}
                      />
                    }
                  />
                  <Route
                    path="/shop"
                    element={
                      <Shop setShowPayment={setShowPaymentAlert} setShowAlert={setShowAlert} alert={showAlert} onHandleNavbar={() => setShowNavbar(!showNavbar)} />
                    }
                  />
                  <Route path="/tasks" element={<Tasks setShowAlert={setShowBalanceAlert} setShowAlertInvite={setShowAlertInvite} alertInvite={showAlertInvite} alert={showBalanceAlert} onHandleNavbar={() => setShowNavbar(!showNavbar)} />} />
                  <Route
                    path="/invite"
                    element={
                      <Invite setShowAlert={setShowAlert} alert={showAlert} onHandleNavbar={() => setShowNavbar(!showNavbar)}/>
                    }
                  />
                </Routes>
                <NavBar />
              </div>
              <Routes>
                <Route path="/game" element={isMobile ? <Game onOpenGame={setShowAlert}/> : <GamePc onOpenGame={setShowAlert} /> } />
              </Routes>
            </Router>
          </>
        )}
      </div>
    </>
  );
}

export default App;
