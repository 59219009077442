import React, { useState } from "react";
import "./shop.css";
import UserName from "../../components/username/UserName";
import UserTickets from "../../components/user-tickets/UserTickets";
import UserKeys from "../../components/user-keys/UserKeys";
import Total from "../../components/total/Total";
import Items from "../../components/items/Items";
import Upgrades from "../../components/upgrades/Upgrades";
import li from "../../img/Bg light top.png";
import { ReactComponent as Key } from "../../img/Key.svg";
import { ReactComponent as Jetton } from "../../img/Jetton.svg";
import { ReactComponent as X } from "../../img/x.svg";
import { ReactComponent as Plus } from "../../img/plustime.svg";
import liBg from "../../img/bg-red.png"
import Blocks from "../../components/blocks/Blocks";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {ReactComponent as Cns} from "../../img/Items card/Coins.svg"
import { ReactComponent as TimeMinus } from "../../img/List Item/Left/Items card/Minus time.svg";
import { ReactComponent as X2 } from "../../img/List Item/Left/Items card/x2.svg";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

const Shop = ({ setShowPayment,setShowAlert, alert, onHandleNavbar }) => {

  const dispatch = useDispatch()

  const balance = useSelector(state => state.currentUser.balance)
  const telegramId= useSelector(state => state.currentUser.telegramId)
  const keysStage = useSelector(state => state.currentUser.keysStage)
  const passesStage = useSelector(state => state.currentUser.passesStage)
  const keys = useSelector(state => state.currentUser.keys)
  const passes = useSelector(state => state.currentUser.passes)
  const id = useSelector(state => state.currentUser.id)
  const upgrades = useSelector(state => state.currentUser.upgrades)
  const passesFarm = useSelector(state => state.currentUser.passesFarm)

  const [openUpgrade1, setOpenUpgrade1] = useState(false);
  const [openUpgrade2, setOpenUpgrade2] = useState(false);
  const [openUpgrade3, setOpenUpgrade3] = useState(false);
  const [openUpgrade4, setOpenUpgrade4] = useState(false);

  const [openItem1, setOpenItem1] = useState(false)
  const [openItem2, setOpenItem2] = useState(false)

  const [showKey, setShowKey] = useState(false);
  const [showTickets, setShowTickets] = useState(false);
  const [showError, setShowError] = useState(false)
  const [itemPrice, setItemPrice] = useState(null)

  const [goBack, setGoBack] = useState(false);

  async function buyPremium() {
    if(!goBack) {
      try {
          await axios.put(`https://niseapp.lol/api/user/change-balance/${telegramId}`, {
            newBalance: balance - 5000
          })
          dispatch({
            type: "change-balance",
            payload: {
              balance: balance - 5000
            }
          })
          if (!alert) {
            setShowPayment(true);
            setTimeout(() => setShowPayment(false), "3000");
          }
      } catch (error) {
        console.log(error);
      }
    }
  }

  async function buyUpgrade(upgradeId, price) {
    if(!goBack) {
      try {
        const {data} = await axios.post(`https://niseapp.lol/api/upgrade/create/${id}/${upgradeId}`)
        if(data.success) {
          await axios.put(`https://niseapp.lol/api/user/change-balance/${telegramId}`, {
            newBalance: balance - price
          })
          dispatch({
            type: "change-upgrades",
            payload: {
              upgrades: [...upgrades, {id: Math.random(), upgrade: upgradeId}]
            }
          })
          dispatch({
            type: "change-balance",
            payload: {
              balance: balance - price
            }
          })
          if (!alert) {
            setShowPayment(true);
            setTimeout(() => setShowPayment(false), "3000");
          }
        }
        console.log(data)
      } catch (error) {
        console.log(error);
      }
    }
  }

  async function buyKey() {
    try {
      const {data} = await axios.put(`https://niseapp.lol/api/user/change-keys-stage/${telegramId}`, {
        newStage: keysStage + 1
      })
      console.log(data)
      if (data.success) {
        await axios.put(`https://niseapp.lol/api/user/change-key/${telegramId}`, {
          newKeyBalance: keys + 1
        })
        await axios.put(`https://niseapp.lol/api/user/change-balance/${telegramId}`, {
          newBalance: balance - itemPrice
        })
        dispatch({
          type: "change-keys",
          payload: {
            keys: keys + 1
          }
        })
        dispatch({
          type: "change-keys-stage",
          payload: {
            keysStage: keysStage + 1
          }
        })
        dispatch({
          type: "change-balance",
          payload: {
            balance: balance - itemPrice
          }
        })
        if (!alert) {
          setShowPayment(true);
          setTimeout(() => setShowPayment(false), "3000");
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function buyPass() {
    try {
      const {data} = await axios.put(`https://niseapp.lol/api/user/change-passes-stage/${telegramId}`, {
        newStage: passesStage + 1
      })
      console.log(data)
      if (data.success) {
        await axios.put(`https://niseapp.lol/api/user/change-passes-farm/${telegramId}`, {
          passes: passesFarm + 1
        })
        await axios.put(`https://niseapp.lol/api/user/change-balance/${telegramId}`, {
          newBalance: balance - itemPrice
        })
        dispatch({
          type: "change-passes-farm",
          payload: {
            passesFarm: passesFarm + 1
          }
        })
        dispatch({
          type: "change-passes-stage",
          payload: {
            passesStage: passesStage + 1
          }
        })
        dispatch({
          type: "change-balance",
          payload: {
            balance: balance - itemPrice
          }
        })
        if (!alert) {
          setShowPayment(true);
          setTimeout(() => setShowPayment(false), "3000");
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
    {showError && (
        <>
          <div
            className="black-zone"
            style={goBack ? { display: "none" } : null}
            onClick={() => {
              setGoBack(true);
              setTimeout(() => {
                setShowError(false);
                onHandleNavbar();
                setGoBack(false);
              }, "900");
            }}
          ></div>
          <div
            className="window"
            style={
              goBack
                ? {
                    maxHeight: "430px",
                    animation: "window_back_rank 0.9s",
                    bottom: "-600px",
                    zIndex: "999999"
                  }
                : { maxHeight: "430px", zIndex: "999999" }
            }
          >
            <div className="window_data">
              <img style={{zIndex: '1'}} src={liBg} width={"100%"} alt="" />
              <div style={{ padding: "16px", height: "100%" }}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <X
                  className="close_x"
                    style={{ opacity: "12%" , zIndex: '2'}}
                    onClick={() => {
                      setGoBack(true);
                      setTimeout(() => {
                        setShowError(false);
                        onHandleNavbar();
                        setGoBack(false);
                      }, "600");
                    }}
                  />
                </div>
                <div style={{textAlign: "center"}}>
                    <Cns />
                    <p className="wnd_header">Not enough balance</p>
                    <span className="wnd_text">
                      You can invite friends through your referral link and get
                      bonuses
                    </span>
                  </div>
                <Blocks style={{position: 'absolute'}}/>
                <div style={{ display: "flex", width: "100%", gap: "8px" }}>
            <CopyToClipboard text={`https://t.me/NiseAppBot?start=${telegramId}`}>
            <div className="start_farm"  style={{ width: "100%", zIndex: '2' }}>
            <button onClick={() => {
                  if (!alert) {
                    setShowAlert(true);
                    setTimeout(() => setShowAlert(false), "3000");
                  }
                }}>Copy a link</button>
          </div>
            </CopyToClipboard>
        </div>
              </div>
            </div>
          </div>
        </>
      )}
    {showTickets && (
        <>
          <div
            className="black-zone"
            style={goBack ? { display: "none" } : null}
            onClick={() => {
              setGoBack(true);
              setTimeout(() => {
                setShowTickets(false);
                onHandleNavbar();
                setGoBack(false);
              }, "600");
            }}
          ></div>
          <div
            className="window"
            style={
              goBack
                ? {
                    maxHeight: "280px",
                    animation: "window_back 0.6s",
                    bottom: "-300px",
                  }
                : { maxHeight: "280px" }
            }
          >
            <div className="window_data">
              <img src={li} width={"100%"} alt="" />
              <div style={{ padding: "16px", height: "100%" }}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <X
                  className="close_x"
                    style={{ opacity: "12%" }}
                    onClick={() => {
                      setGoBack(true);
                      setTimeout(() => {
                        setShowTickets(false);
                        onHandleNavbar();
                        setGoBack(false);
                      }, "600");
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "relative",
                    height: "100%",
                    textAlign: "center",
                  }}
                >
                  <div>
                    <Jetton />
                    <p className="wnd_header">Game pass</p>
                    <span className="wnd_text">
                    To get passes, click "Start Farm" on the main page. You can also increase the amount of mining in the upgrades tab.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {showKey && (
        <>
          <div
            style={goBack ? { display: "none" } : null}
            className="black-zone"
            onClick={() => {
              setGoBack(true);
              setTimeout(() => {
                setShowKey(false);
                onHandleNavbar();
                setGoBack(false);
              }, "600");
            }}
          ></div>
          <div
            className="window"
            style={
              goBack
                ? {
                    maxHeight: "280px",
                    animation: "window_back 0.6s",
                    bottom: "-300px",
                  }
                : { maxHeight: "280px" }
            }
          >
            <div className="window_data">
              <img src={li} width={"100%"} alt="" />
              <div style={{ padding: "16px", height: "100%" }}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <X
                  className="close_x"
                    style={{ opacity: "12%" }}
                    onClick={() => {
                      setGoBack(true);
                      setTimeout(() => {
                        setShowKey(false);
                        onHandleNavbar();
                        setGoBack(false);
                      }, "600");
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "relative",
                    height: "100%",
                    textAlign: "center",
                  }}
                >
                  <div>
                    <Key />
                    <p className="wnd_header">Box Key</p>
                    <span className="wnd_text">
                    Use this key to open a box and get a new rank. Keys can be bought in the upgrade tab or earned by inviting friends.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="pg"
      style={{
        marginBottom: "0px",
        display: "block",
        overFlowY: "auto",
        marginLeft: "0px",
        marginRight: "0px",
      }} >
        <div className="home_pg" style={{justifyContent: "flex-start"}}>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <UserName />
          <div style={{ display: "flex", gap: "6px" }}>
            <UserTickets onTickets={() => {
                    if (!goBack) {
                      setShowTickets(true);
                      onHandleNavbar();
                    }
                  }}/>
            <UserKeys onShowKey={() => {
                    if (!goBack) {
                      setShowKey(true);
                      onHandleNavbar();
                    }
                  }}/>
          </div>
        </div>
        <Total />
        <p className="pg_header_s">Items</p>
        <Items 
            onShowError={() => {
              if(!goBack) {
                setShowError(true)
                onHandleNavbar();
              }
            }}
              open1={(price) => {
                setItemPrice(price)
                if (!goBack) {
                  setOpenItem1(true);
                  onHandleNavbar();
                }
              }}
              open2={(price) => {
                setItemPrice(price)
                if (!goBack) {
                  setOpenItem2(true);
                  onHandleNavbar();
                }
              }}
        />
        <p className="pg_header_s" style={{ marginTop: "20px" }}>
          Upgrades
        </p>
        <Upgrades
        onShowError={() => {
          if(!goBack) {
            setShowError(true)
            onHandleNavbar();
          }
        }}
          open1={() => {
            if (!goBack) {
              setOpenUpgrade1(true);
              onHandleNavbar();
            }
          }}
          open2={() => {
            if (!goBack) {
              setOpenUpgrade2(true);
              onHandleNavbar();
            }
          }}
          open3={() => {
            if (!goBack) {
              setOpenUpgrade3(true);
              onHandleNavbar();
            }
          }}
          open4={() => {
            if (!goBack) {
              setOpenUpgrade4(true);
              onHandleNavbar();
            }
          }}
        />
      </div>
      </div>
      {openItem1 && (
        <>
          <div
            className="black-zone"
            onClick={() => {
              setGoBack(true);
              setTimeout(() => {
                setOpenItem1(false);
                onHandleNavbar();
                setGoBack(false);
              }, "500");
            }}
            style={goBack ? { display: "none" } : null}
          ></div>
          <div
            className="window"
            style={
              goBack
                ? {
                    animation: "window_back 0.6s",
                    bottom: "-300px",
                  }
                : null
            }
          >
            <div className="window_data">
              <img src={li} width={"100%"} alt="" />
              <div style={{ padding: "16px", height: "100%" }}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <X
                  className="close_x"
                    style={{ opacity: "12%" }}
                    onClick={() => {
                      setGoBack(true);
                      setTimeout(() => {
                        setOpenItem1(false);
                        onHandleNavbar();
                        setGoBack(false);
                      }, "500");
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "relative",
                    height: "100%",
                    textAlign: "center",
                  }}
                >
                  <div>
                  <Key />
                    <p className="wnd_header">Buy 1 key</p>
                    <p className="wnd_text">
                    This key gives you the opportunity to open a box and get a new rank from the box.
                    </p>
                  </div>
                  <button className="by" onClick={() => {
                    buyKey()
                    setGoBack(true);
                    setTimeout(() => {
                      setOpenItem1(false);
                      onHandleNavbar();
                      setGoBack(false);
                    }, "500");  
                    }}>Buy</button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {openItem2 && (
        <>
          <div
            className="black-zone"
            onClick={() => {
              setGoBack(true);
              setTimeout(() => {
                setOpenItem2(false);
                onHandleNavbar();
                setGoBack(false);
              }, "500");
            }}
            style={goBack ? { display: "none" } : null}
          ></div>
          <div
            className="window"
            style={
              goBack
                ? {
                    animation: "window_back 0.6s",
                    bottom: "-300px",
                  }
                : null
            }
          >
            <div className="window_data">
              <img src={li} width={"100%"} alt="" />
              <div style={{ padding: "16px", height: "100%" }}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <X
                  className="close_x"
                    style={{ opacity: "12%" }}
                    onClick={() => {
                      setGoBack(true);
                      setTimeout(() => {
                        setOpenItem2(false);
                        onHandleNavbar();
                        setGoBack(false);
                      }, "500");
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "relative",
                    height: "100%",
                    textAlign: "center",
                  }}
                >
                  <div>
                    <Jetton />
                    <p className="wnd_header">Add 1 pass per farm</p>
                    <p className="wnd_text">
                    The number of passes received during the entire farming period will increase by 1.
                    </p>
                  </div>
                  <button className="by" onClick={() => {
                    buyPass()
                    setGoBack(true);
                        setTimeout(() => {
                          setOpenItem2(false);
                          onHandleNavbar();
                          setGoBack(false);
                        }, "500")
                    }}>Buy</button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {openUpgrade1 && (
        <>
          <div
            className="black-zone"
            onClick={() => {
              setGoBack(true);
              setTimeout(() => {
                setOpenUpgrade1(false);
                onHandleNavbar();
                setGoBack(false);
              }, "500");
            }}
            style={goBack ? { display: "none" } : null}
          ></div>
          <div
            className="window"
            style={
              goBack
                ? {
                    animation: "window_back 0.6s",
                    bottom: "-300px",
                  }
                : null
            }
          >
            <div className="window_data">
              <img src={li} width={"100%"} alt="" />
              <div style={{ padding: "16px", height: "100%" }}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <X
                  className="close_x"
                    style={{ opacity: "12%" }}
                    onClick={() => {
                      setGoBack(true);
                      setTimeout(() => {
                        setOpenUpgrade1(false);
                        onHandleNavbar();
                        setGoBack(false);
                      }, "500");
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "relative",
                    height: "100%",
                    textAlign: "center",
                  }}
                >
                  <div>
                    <Plus />
                    <p className="wnd_header">+2 seconds to the game</p>
                    <p className="wnd_text">
                      Your game time will increase by two seconds after
                      purchasing this upgrade.
                    </p>
                  </div>
                  <button className="by" onClick={() => {
                    setGoBack(true);
                    setTimeout(() => {
                      setOpenUpgrade1(false);
                      onHandleNavbar();
                      setGoBack(false);
                    }, "500");
                    buyUpgrade(1, 600)}
                    }>Buy</button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {openUpgrade2 && (
        <>
          <div
            className="black-zone"
            onClick={() => {
              setGoBack(true);
              setTimeout(() => {
                setOpenUpgrade2(false);
                onHandleNavbar();
                setGoBack(false);
              }, "500");
            }}
            style={goBack ? { display: "none" } : null}
          ></div>
          <div
            className="window"
            style={
              goBack
                ? {
                    animation: "window_back 0.6s",
                    bottom: "-300px",
                  }
                : null
            }
          >
            <div className="window_data">
              <img src={li} width={"100%"} alt="" />
              <div style={{ padding: "16px", height: "100%" }}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <X
                  className="close_x"
                    style={{ opacity: "12%" }}
                    onClick={() => {
                      setGoBack(true);
                      setTimeout(() => {
                        setOpenUpgrade2(false);
                        onHandleNavbar();
                        setGoBack(false);
                      }, "500");
                    }}
                  />
                  
                </div>
                <div
                  style={{
                    position: "relative",
                    height: "100%",
                    textAlign: "center",
                  }}
                >
                  <div>
                  <p className="p_ico" style={{fontSize: "54px", height: '80px'}}>X</p>
                    <p className="wnd_header">Buy add-on X</p>
                    <span className="wnd_text">
                    This is a unique rank that was created by our team specifically for OG users.
                    </span>
                  </div>
                  <button className="by" onClick={() => {
                    setGoBack(true);
                    setTimeout(() => {
                      setOpenUpgrade2(false);
                      onHandleNavbar();
                      setGoBack(false);
                    }, "500");
                    buyUpgrade(2, 7777)
                    }}>Buy</button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {openUpgrade4 && (
        <>
          <div
            className="black-zone"
            onClick={() => {
              setGoBack(true);
              setTimeout(() => {
                setOpenUpgrade4(false);
                onHandleNavbar();
                setGoBack(false);
              }, "500");
            }}
            style={goBack ? { display: "none" } : null}
          ></div>
          <div
            className="window"
            style={
              goBack
                ? {
                    animation: "window_back 0.6s",
                    bottom: "-300px",
                  }
                : null
            }
          >
            <div className="window_data">
              <img src={li} width={"100%"} alt="" />
              <div style={{ padding: "16px", height: "100%" }}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <X
                  className="close_x"
                    style={{ opacity: "12%" }}
                    onClick={() => {
                      setGoBack(true);
                      setTimeout(() => {
                        setOpenUpgrade4(false);
                        onHandleNavbar();
                        setGoBack(false);
                      }, "500");
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "relative",
                    height: "100%",
                    textAlign: "center",
                  }}
                >
                  <div>
                    <X2 style={{width: '80px', height: '80px'}}/>
                    <p className="wnd_header">x2 to win</p>
                    <span className="wnd_text">
                    All the buds you collect while playing our game will increase by 2 times.
                    </span>
                  </div>
                  <button className="by" onClick={() => {
                    setGoBack(true);
                    setTimeout(() => {
                      setOpenUpgrade4(false);
                      onHandleNavbar();
                      setGoBack(false);
                    }, "500");
                    buyUpgrade(4, 5500)
                    }}>Buy</button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {openUpgrade3 && (
        <>
          <div
            className="black-zone"
            onClick={() => {
              setGoBack(true);
              setTimeout(() => {
                setOpenUpgrade3(false);
                onHandleNavbar();
                setGoBack(false);
              }, "500");
            }}
            style={goBack ? { display: "none" } : null}
          ></div>
          <div
            className="window"
            style={
              goBack
                ? {
                    animation: "window_back 0.6s",
                    bottom: "-300px",
                  }
                : null
            }
          >
            <div className="window_data">
              <img src={li} width={"100%"} alt="" />
              <div style={{ padding: "16px", height: "100%" }}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <X
                  className="close_x"
                    style={{ opacity: "12%" }}
                    onClick={() => {
                      setGoBack(true);
                      setTimeout(() => {
                        setOpenUpgrade3(false);
                        onHandleNavbar();
                        setGoBack(false);
                      }, "500");
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "relative",
                    height: "100%",
                    textAlign: "center",
                  }}
                >
                  <div>
                    <p className="p_ico" style={{fontSize: "54px", height: '80px'}}>P</p>
                    <p className="wnd_header">Buy Premium rank</p>
                    <span className="wnd_text">
                    This rank will increase your Airdrop by 20%.
                    </span>
                  </div>
                  <button className="by" onClick={() => {
                    setGoBack(true);
                    setTimeout(() => {
                      setOpenUpgrade3(false);
                      onHandleNavbar();
                      setGoBack(false);
                    }, "500");
                    buyUpgrade(3, 5000)
                    }}>Buy</button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Shop;
