import React from "react";
import "./loading.css";
import { ReactComponent as Loader } from "../../img/Logo_loader.svg";

const Loading = () => {
  return (
    <div className="loading">
      <div>
			<svg
			xmlns="http://www.w3.org/2000/svg"
            width="100"
            height="100"
			fill="#2d3337"
			viewBox="0 0 50 50"
		>
			<defs>
				<clipPath id="pt1">
					<path d="M9.668 11.897v25.219l3.12-2.815V14.75L32.49 41.793l6.885-2.853V14.294l-3.12 2.89V36.28L16.288 8.969l-6.619 2.928z"></path>
				</clipPath>
				<clipPath id="pt2">
					<path d="M15.184 37.953v-15.52l2.89 3.804v11.716l-5.705 5.4-3.385.495 6.2-5.895zM30.968 25.02l2.929 3.727-.038-14.91 7.797-7.075-1.483-1.217-9.205 8.292V25.02zM24.997.142c.592 0 1.18.02 1.762.062l-3.223 3.269C12.312 4.223 3.44 13.566 3.44 24.98c0 3.654.91 7.097 2.514 10.112l-2.45 2.346A24.723 24.723 0 01.158 24.98C.159 11.262 11.279.142 24.997.142zM29.739 3.947a21.49 21.49 0 00-2.036-.355L30.5.754c.659.149 1.307.324 1.945.524l-2.707 2.67zM24.997 49.817c13.718 0 24.838-11.12 24.838-24.837 0-3.958-.926-7.699-2.572-11.02l-2.542 2.31a21.48 21.48 0 011.832 8.71c0 11.473-8.963 20.852-20.27 21.518l-3.164 3.25c.62.046 1.246.07 1.878.07z"></path>
				</clipPath>
				<clipPath id="pt3">
					<path d="M17.39 48.632L20 45.953a21.482 21.482 0 01-8.697-4.326L8.916 43.91a24.795 24.795 0 008.475 4.722zM34.521 2.034L31.949 4.57a21.54 21.54 0 017.378 4.307l2.44-2.218a24.854 24.854 0 00-7.246-4.625z"></path>
				</clipPath>
			</defs>
		</svg>
			<div className={"logo"}>
				<div className={"logoPt1"}></div>
				<div className={"logoPt2"}></div>
				<div className={"logoPt3"}></div>
			</div>
		</div>
      </div>
  );
};

export default Loading;
