import { React, useState, useRef, useEffect, useCallback } from "react";
import "./game.css";
import {ReactComponent as Bag} from "../../img/bag.svg";
import Bud from "../../img/bud.svg"
import { ReactComponent as Clock } from "../../img/Clock.svg";
import { ReactComponent as LogoMark } from "../../img/LogoMark.svg";
import { useNavigate } from "react-router-dom";
import GameEnd from "../../components/game-end/GameEnd";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import GameLoading from "../../components/game-loading/GameLoading";
import axios from "axios";

const Game = ({ onOpenGame }) => {
  const dispatch = useDispatch();
  const passes = useSelector((state) => state.currentUser.passes);
  const telegramId = useSelector(state => state.currentUser.telegramId);
  const navigate = useNavigate();

  const minusPass = async () => {
    try {
      await axios.put(`https://niseapp.lol/api/user/change-pass/${telegramId}`, {
        newPassesBalance: passes - 1
      });
      dispatch({
        type: "change-passes",
        payload: {
          passes: passes - 1
        }
      })
    } catch (error) {
      console.log(error)
      navigate('/')
    }
  }

  useEffect(() => {
  if(passes <= 0) {
    navigate('/')
  }else {
    minusPass()
  }
}, [])

  useEffect(() => {
    const tg = window.Telegram.WebApp;
    tg.BackButton.show();
    tg.BackButton.onClick(() => {
      tg.BackButton.hide();
      navigate("/");
    });
    return () => {
      tg.BackButton.hide();
    };
  }, []);

  const [loading, setLoading] = useState(true);
  const [seconds, setSeconds] = useState(30);
  const [position, setPosition] = useState({ top: window.innerHeight - 70, left: null });
  const [isDragging, setIsDragging] = useState(false);
  const draggableRef = useRef(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [flowers, setFlowers] = useState([]);
  const [balance, setBalance] = useState(0);
  const [gameStarted, setGameStarted] = useState(false); // Новое состояние для отслеживания начала игры

  const handleTouchStart = useCallback(() => {
    setIsDragging(true);
    if (!gameStarted) setGameStarted(true); // Запускаем игру при первом взаимодействии
  }, [gameStarted]);

  const handleTouchEnd = useCallback(() => {
    setIsDragging(false);
  }, []);

  const handleTouchMove = useCallback((e) => {
    if (isDragging) {
        e.preventDefault(); // Предотвращаем прокрутку страницы
        const touch = e.touches[0];
        setPosition({ left: touch.clientX - 40, top: touch.clientY - 70 });
    }
}, [isDragging]);

  const handleMouseDown = useCallback((e) => {
    setIsDragging(true);
    if (!gameStarted) setGameStarted(true); // Запускаем игру при первом взаимодействии
    const shiftX = e.clientX - draggableRef.current.getBoundingClientRect().left;
    const shiftY = e.clientY - draggableRef.current.getBoundingClientRect().top;

    const moveAt = (pageX, pageY) => {
      setPosition({ left: pageX - shiftX, top: pageY - shiftY });
    };

    const onMouseMove = (event) => {
      moveAt(event.pageX, event.pageY);
    };

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener(
      "mouseup",
      () => {
        document.removeEventListener("mousemove", onMouseMove);
        setIsDragging(false);
        setPosition((prevPosition) => ({
          left: prevPosition.left,
          top: screenHeight - 70,
        }));
      },
      { once: true }
    );
  }, [screenHeight, gameStarted]);

  useEffect(() => {
    if (gameStarted && seconds > 0) {
      const interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [seconds, gameStarted]);

  const createFlower = useCallback(() => {
    const newFlower = {
      id: Date.now(),
      left: Math.round(Math.random() * (screenWidth - 42)),
      top: -110,
      transform: Math.round(Math.random() * (-30 - 10) + 10),
    };
    setFlowers((prevFlowers) => [...prevFlowers, newFlower]);
  }, [screenWidth]);

  useEffect(() => {
    if (gameStarted && seconds > 0) {
      const interval = setInterval(createFlower, 250); // Увеличьте интервал, если необходимо
      return () => clearInterval(interval);
    }
  }, [seconds, gameStarted, createFlower]);

  const updateFlowers = useCallback(() => {
    setFlowers((prevFlowers) =>
      prevFlowers
        .map((flower) => ({
          ...flower,
          top: flower.top + 8,
        }))
        .filter((flower) => flower.top <= screenHeight + 8)
    );
  }, [screenHeight]);

  useEffect(() => {
    let animationFrameId;
    if (gameStarted) {
      const animate = () => {
        updateFlowers();
        animationFrameId = requestAnimationFrame(animate);
      };
      animate();
    }
    return () => cancelAnimationFrame(animationFrameId);
  }, [updateFlowers, gameStarted]);

  useEffect(() => {
    const collectedFlowers = flowers.filter((el) =>
      el.left >= position.left - 15 &&
      el.left <= position.left + 70 &&
      el.top >= position.top &&
      el.top < position.top + 100
    );

    if (collectedFlowers.length > 0) {
      setFlowers((prevFlowers) =>
        prevFlowers.filter((fl) => !collectedFlowers.includes(fl))
      );
      setBalance((prevBalance) => prevBalance + collectedFlowers.length);
    }
  }, [position, flowers]);

  useEffect(() => {
    setTimeout(() => {
      const interval = setInterval(() => {
        if (document.readyState == "complete") {
          setLoading(false)
          clearInterval(interval);
        }
      }, 250)
      return () => {
        clearInterval(interval);
      };
    }, "1800");
  }, []);

  return (
    loading ? (
      <GameLoading />
    ) : (
      <div className="game">
        {seconds <= 0 ? (
          <GameEnd balance={balance} restart={() => {
            setSeconds(30)
            setGameStarted(false)
            setBalance(0)
            setFlowers([])
            setPosition({top: window.innerHeight - 70, left: null })
            minusPass()
          }}/>
        ) : (
          <>
            <div className="li" style={{ zIndex: '99999999' }}></div>
            <div className="gm_pn">
              <div>
                <Clock />
                <span>00:{seconds < 10 && '0'}{seconds}</span>
              </div>
              <div>
                <LogoMark />
                <span>{balance}</span>
              </div>
            </div>
            <div className="game_panel">
              <div className="catch-text" style={gameStarted ? {opacity: "0%"} : null}>Catch buds with a basket</div>
              {flowers.map((flower) => (
                <img
                  key={flower.id}
                  src={Bud}
                  fetchPriority="high"
                  className="cn"
                  style={{
                    left: `${flower.left}px`,
                    top: `${flower.top}px`,
                    transform: `rotate(${flower.transform}deg)`,
                  }}
                />
              ))}
              <div
                className="bag"
                ref={draggableRef}
                onMouseDown={handleMouseDown}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
                style={{
                  width: "100px",
                  height: "70px",
                  position: "absolute",
                  cursor: isDragging ? "grabbing" : "grab",
                  top: !isDragging ? position.top = screenHeight - 115 : Math.min(Math.max(position.top, 50), screenHeight - 115),
                  left: position.left === null ? "auto" : Math.min(Math.max(position.left, 0), screenWidth - 100),
                  transition: isDragging ? "all ease" : "all 1s ease",
                  willChange: "top, left"
                }}
              >
              <Bag style={{ width: "100px", height: "70px" }} />
              </div>
            </div>
          </>
        )}
      </div>
    )
  );
};

export default Game;