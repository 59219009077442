import React from "react";
import { ReactComponent as Phone } from "../../img/Rotationphone.svg";
import "./turn.css";

const Turn = () => {
  return (
    <div className="turn-page">
      <div>
        <Phone />
        <span>Turn the phone upright</span>
      </div>
    </div>
  );
};

export default Turn;
