import React from "react";
import "./navbar.css";
import { ReactComponent as Invite } from "../../img/Nav bar/Icon-1.svg";
import { ReactComponent as Tasks } from "../../img/Nav bar/Icon-2.svg";
import { ReactComponent as Home } from "../../img/Nav bar/Icon.svg";
import { ReactComponent as Store } from "../../img/Nav bar/Icon1.svg";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import UAParser from "ua-parser-js";

const NavBar = () => {
  const location = useLocation();
  const parser = new UAParser();
  const result = parser.getResult();
  return (
    <div
      className="navbar"
      style={
        location.pathname === "/game"
          ? { display: "none" }
          : result.os.name == "iOS"
          ? { minHeight: "95px", alignItems: "flex-start", paddingTop: "15px" }
          : null
      }
    >
      <Link
        to={"/"}
        style={location.pathname === "/" ? { opacity: "100%" } : null}
      >
        <Home className="icon" />
        <span>Home</span>
      </Link>
      <Link
        to={"/shop"}
        style={location.pathname === "/shop" ? { opacity: "100%" } : null}
      >
        <Store className="icon" />
        <span>Store</span>
      </Link>
      <Link
        to={"/invite"}
        style={location.pathname === "/invite" ? { opacity: "100%" } : null}
      >
        <Invite className="icon" />
        <span>Invite</span>
      </Link>
      <Link
        to={"/tasks"}
        style={location.pathname === "/tasks" ? { opacity: "100%" } : null}
      >
        <Tasks className="icon" />
        <span>Tasks</span>
      </Link>
    </div>
  );
};

export default NavBar;
