import React, { useEffect } from "react";
import "./game-end.css";
import { ReactComponent as Logo } from "../../img/ll.svg";
import {ReactComponent as Jetton} from "../../img/Items card/Jetton.svg"
import { useSelector } from "react-redux";
import axios from "axios";
import { useDispatch } from "react-redux";

const GameEnd = ({ balance, restart}) => {
  const dispatch = useDispatch()
  const passes = useSelector(state => state.currentUser.passes)
  let upgrades = useSelector(state => state.currentUser.upgrades)
  const telegramId = useSelector(state => state.currentUser.telegramId)
  const userBalance = useSelector(state => state.currentUser.balance)

  if (upgrades === undefined) {
    upgrades = []
  }

  const addBalance = async () => {
    try {
      await axios.put(`https://niseapp.lol/api/user/change-balance/${telegramId}`, {
        newBalance: upgrades.some(e => e.upgrade === 4) ? userBalance + (balance * 2) : userBalance + balance
      })
      dispatch({
        type: "change-balance",
        payload: {
          balance: upgrades.some(e => e.upgrade === 4) ? userBalance + (balance * 2) : userBalance + balance
        }
      })
    } catch (error) {
      console.log(error)
    }
  } 

  useEffect(() => {
    addBalance(); 
  }, []);

  return (
    <div className="game_end">
      <div>
        <Logo />
        <p>Very good!</p>
        <span className="end_text">
          You have collected a good number of petals, and for that you are
          rewarded in the form of
        </span>
        <div className="end_balance">
          {upgrades.some(e => e.upgrade === 4) ? (
            <span>+{balance * 2} NISE</span>
          ) : (
            <span>+{balance} NISE</span>
          )}
        </div>
        {
          passes > 0 && (
            <button onClick={restart} className="by" style={{width: "89%", left: "auto", gap: "6px"}}>
            <span>Play again</span>
            <Jetton />
            <span>{passes}</span>
            </button>
          )
        }
      </div>
    </div>
  );
};

export default GameEnd;
