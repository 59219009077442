import React, { useEffect, useState } from "react";
import "./home.css";
import UserData from "../../components/user-data/UserData";
import UserBalance from "../../components/user-balance/UserBalance";
import UserTickets from "../../components/user-tickets/UserTickets";
import UserKeys from "../../components/user-keys/UserKeys";
import Box from "../../components/box/Box";
import StartFarm from "../../components/start-farm/StartFarm";
import { ReactComponent as X } from "../../img/x.svg";
import { ReactComponent as Key } from "../../img/Key.svg";
import { ReactComponent as Jetton } from "../../img/Jetton.svg";
import { ReactComponent as Chev } from "../../img/Actions/Chevron left.svg";
import li from "../../img/Bg light top.png";
import liBg from "../../img/li-bg-top.png"
import UAParser from "ua-parser-js";
import { useSelector } from "react-redux";
import { ReactComponent as RankType1 } from "../../img/rank_type_1.svg";
import { ReactComponent as RankType2 } from "../../img/rank_type_2.svg";
import { ReactComponent as RankType3 } from "../../img/rank_type_3.svg";
import { ReactComponent as RankType4 } from "../../img/rank_type_4.svg";
import {ReactComponent as Arr} from "../../img/Actions/Arrow double.svg"
import axios from "axios";
import { useDispatch } from "react-redux";
import { ReactComponent as Keys } from "../../img/Items card/Icon-1.svg";
import confetti from 'canvas-confetti';

const Home = ({ onHandleNavbar, onHandleTickets, onHandleKeys, error }) => {

  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    const loadImage = () => {
      const img = new Image();
      img.src = require('../../img/confetti2.gif');
      img.onload = () => {
          setImageUrl(img.src); 
      };
  };

  loadImage();
  }, [])

  const dispatch = useDispatch()
  const rank = useSelector(state => state.currentUser.rank)
  const telegramId = useSelector(state => state.currentUser.telegramId)
  const rankType = useSelector(state => state.currentUser.rankType)
  const [showConfetti, setShowConfetti] = useState(false)
  const [ranks, setRanks] = useState( [
    { id: 1, name: 'Jonokami' },
    { id: 2, name: 'Chonin' },
    { id: 3, name: 'Jonzin' },
    { id: 4, name: 'Keninin' },
    { id: 5, name: 'Kanzaki' },
    { id: 6, name: 'Choseinin' },
    { id: 7, name: 'Kusa' },
    { id: 8, name: 'Suigyonin' },
    { id: 9, name: 'Kitanin' },
    { id: 10, name: 'Kuchinami' },
    { id: 11, name: 'Ujonin' },
    { id: 12, name: 'Kagenin' },
    { id: 13, name: 'Nagarenin' },
    { id: 14, name: 'Yokainin' },
    { id: 15, name: 'Seron' },
    { id: 16, name: 'Bakenanin' },
    { id: 17, name: 'Rainin' },
    { id: 18, name: 'Shinobiwuno' },
    { id: 19, name: 'Mizunin' },
    { id: 20, name: 'Tenin' },
    { id: 21, name: 'Irukanin' },
    { id: 22, name: 'Sorunin' },
    { id: 23, name: 'Yoginin' },
    { id: 24, name: 'Kuronin' },
    { id: 25, name: 'Moronin' },
    { id: 26, name: 'Kakinenin' },
    { id: 27, name: 'Hiyonin' },
    { id: 28, name: 'Satsuzonin' },
    { id: 29, name: 'Narentin' },
    { id: 30, name: 'Meinin' }
  ])

  
  const [rankPosition, setRankPosition] = useState(0)
  const [showKey, setShowKey] = useState(false);
  const [showTickets, setShowTickets] = useState(false);
  const [showRank, setShowRank] = useState(false)
  const [openBox, setOpenBox] = useState(false)
  const [goBack, setGoBack] = useState(false);
  const parser = new UAParser();
  const result = parser.getResult();

  const margin =
    result.os.name == "iOS"
      ? { marginBottom: "95px", marginLeft: "0px", marginRight: "0px" }
      : { marginBottom: "64px", marginLeft: "0px", marginRight: "0px" };

  const keys = useSelector(state => state.currentUser.keys)
  
  function getRankType(id) {
    if (id <= 10) {
      return <RankType1 />
    }else if(id <= 20) {
      return <RankType2 />
    }else if(id <= 25) {
      return <RankType3 />
    }else {
      return <RankType4 />
    }
  }

  function rankColor(id) {
    if (id <= 10) {
      return {color: "#C69C6D"}
    }else if(id <= 20) {
      return {color: "#B2CCCC"}
    }else if(id <= 25) {
      return {color: "#FED100"}
    }else {
      return {color: "#6FDDE7"}
    }
  }

  const addRank = async () => {
    if(rank !== 30) {
      try {
        await axios.put(`https://niseapp.lol/api/user/change-key/${telegramId}`, {
          newKeyBalance: Math.floor((keys - 1) * 10) / 10
        })
        await axios.put(`https://niseapp.lol/api/user/change-rank/${telegramId}`, {
          newRank: rank + 1
        })
      } catch (error) {
        console.log(error)
      } finally {
        launchConfetti()
      }
      if(rank === 10 || rank === 20 || rank === 25) {
        try {
          await axios.put(`https://niseapp.lol/api/user/change-rank-type/${telegramId}`, {
            newRankType: rankType + 1
          })
          dispatch({
            type: "change-rank-type",
            payload: {
              rankType: rankType + 1
            }
          })
        } catch (error) {
          console.log(error)
        }
      }
      dispatch({
        type: "change-keys",
        payload: {
          keys: Math.floor((keys - 1) * 10) / 10
        }
      })
      dispatch({
        type: "change-rank",
        payload: {
          rank: rank + 1
        }
      })

    }
  }

  const launchConfetti = () => {
    confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 }
    });
};

  return (
    <>
    {openBox && (
        <>
          <div
            className="black-zone"
            style={goBack ? { display: "none" } : null}
            onClick={() => {
              setGoBack(true);
              setTimeout(() => {
                setOpenBox(false);
                onHandleNavbar();
                setGoBack(false);
              }, "600");
            }}
          ></div>
          <div
            className="window"
            style={
              goBack
                ? {
                    animation: "window_back_rank 0.9s",
                    bottom: "-600px",
                  }
                : rank === 30 ? { maxHeight: "420px" } : { maxHeight: "530px" }
            }
          >
            <div className="window_data">
              <img src={liBg} width={"100%"} alt="" />
              <div style={{ padding: "16px", height: "100%" }}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <X
                  className="close_x"
                    style={{ opacity: "12%" }}
                    onClick={() => {
                      setGoBack(true);
                      setTimeout(() => {
                        setOpenBox(false);
                        onHandleNavbar();
                        setGoBack(false);
                      }, "600");
                    }}
                  />
                </div>
                <div className="open-box-panel">
                  <div className="open-box-head">
                    <p className="open-box-header">Rank increased</p>
                    <span className="wnd_text" style={{
                      textAlign: "center"
                    }}>Here you can increase the rank, your Airdrop depends on it.</span>
                  </div>
                  {showConfetti && <img src={imageUrl} className="confetti" loading="lazy"/>}
                  <div className="ranks_change">
                    {
                      rank === 30 ? (
                        <div className="rank_panels">
                          <div className="rank_in_panel">
                            <p style={rankColor(rank)}>{rank}</p>
                            {getRankType(rank)}
                          </div>
                          <p>{ranks.find(r => r.id === rank).name}</p>
                          <span>Your Rank</span>
                        </div>
                      ) : (
                        <>
                        <div className="rank_panels">
                        <div className="rank_in_panel">
                          <p style={rankColor(rank)}>{rank}</p>
                          {getRankType(rank)}
                        </div>
                        <p>{ranks.find(r => r.id === rank).name}</p>
                        <span>Your Rank</span>
                      </div>
                      <Arr />
                      <div className="rank_panels">
                        <div className="rank_in_panel">
                          <p style={rankColor(rank + 1)}>{rank + 1}</p>
                          {getRankType(rank + 1)}
                        </div>
                        <p>{ranks.find(r => r.id === rank + 1).name}</p>
                        <span>Airdrop rank</span>
                      </div>
                        </>
                      )
                    }
                  </div>
                  <div className="open-box-footer">
                    <div className="start_farm">
                      <div className="start_play">
                      <div>
                        <div className="fw">
                          <Keys style={{minHeight: "24px", minWidth: "24px"}}/>
                        </div>
                        <div className="flw_texts">
                          <p>{keys}</p>
                          <span>There are keys left</span>
                        </div>  
                      </div>
                    </div>
                    {rank != 30 && <button className="open-btn" style={{zIndex: "99999"}} onClick={() => {
                        if (keys < 1) {
                          if (!error) {
                            onHandleKeys(true);
                            setTimeout(() => {
                              onHandleKeys(false);
                            }, "3000");
                          } 
                        } else {
                          addRank()
                        }
                        // setShowConfetti(true)
                        // setTimeout(() => {
                        //   setShowConfetti(false)
                        // }, "1300");
                      }}>Open Box</button>}
                  </div>
                  </div>
                  </div>
              </div>
            </div>
          </div>
        </>
      )}
      {showRank && (
        <>
          <div
            className="black-zone"
            style={goBack ? { display: "none" } : null}
            onClick={() => {
              setGoBack(true);
              setTimeout(() => {
                setShowRank(false);
                onHandleNavbar();
                setGoBack(false);
              }, "600");
            }}
          ></div>
          <div
            className="window"
            style={
              goBack
                ? {
                    maxHeight: "400px",
                    animation: "window_back_rank 0.9s",
                    bottom: "-600px",
                  }
                : { maxHeight: "400px" }
            }
          >
            <div className="window_data">
              <img src={liBg} width={"100%"} alt="" />
              <div style={{ padding: "16px", height: "100%" }}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <X
                  className="close_x"
                    style={{ opacity: "12%" }}
                    onClick={() => {
                      setGoBack(true);
                      setTimeout(() => {
                        setShowRank(false);
                        onHandleNavbar();
                        setGoBack(false);
                      }, "600");
                    }}
                  />
                </div>
                <div className="ranks-slider">
                  <div style={{cursor: 'pointer', zIndex: '9999999'}} onClick={() => {
                    if(rankPosition != 0){
                      setRankPosition(rankPosition + 113)
                    }
                  }}><Chev /></div>
                  <div className="rr_">
                    <div style={{left: `${rankPosition}px`, overflow: 'hidden'}}>
                    {
                      ranks.map(el => {
                        return (
                          <div className="rank_panels">
                            <div className="rank_in_panel">
                              <p style={rankColor(el.id)}>{el.id}</p>
                              {getRankType(el.id)}
                            </div>
                            <p>{el.name}</p>
                            <span>{el.id === rank ? 'Your Rank' : 'Airdrop rank'}</span>
                          </div>
                        )
                      })
                    }
                    </div>
                  </div>
                  <div style={{cursor: 'pointer'}} onClick={() => {
                    if(-3277 != rankPosition) {
                      setRankPosition(rankPosition - 113)
                    }
                  }}> <Chev style={{transform: 'rotate(180deg)'}}/></div>
                </div>
              <div className="rank-window-text">
                <p>How do you raise your status?</p>
                <span>To increase your status, you need to open chests from which the status falls. Chests can be opened with the help of keys, which can be purchased in the store for coins obtained in the game by collecting flowers</span>
              </div>
              </div>
            </div>
          </div>
        </>
      )}
      {showTickets && (
        <>
          <div
            className="black-zone"
            style={goBack ? { display: "none" } : null}
            onClick={() => {
              setGoBack(true);
              setTimeout(() => {
                setShowTickets(false);
                onHandleNavbar();
                setGoBack(false);
              }, "600");
            }}
          ></div>
          <div
            className="window"
            style={
              goBack
                ? {
                    maxHeight: "280px",
                    animation: "window_back 0.6s",
                    bottom: "-300px",
                  }
                : { maxHeight: "280px" }
            }
          >
            <div className="window_data">
              <img src={li} width={"100%"} alt="" />
              <div style={{ padding: "16px", height: "100%" }}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <X
                  className="close_x"
                    style={{ opacity: "12%" }}
                    onClick={() => {
                      setGoBack(true);
                      setTimeout(() => {
                        setShowTickets(false);
                        onHandleNavbar();
                        setGoBack(false);
                      }, "600");
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "relative",
                    height: "100%",
                    textAlign: "center",
                  }}
                >
                  <div>
                    <Jetton />
                    <p className="wnd_header">Game pass</p>
                    <span className="wnd_text">
                    To get passes, click "Start Farm" on the main page. You can also increase the amount of mining in the upgrades tab.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {showKey && (
        <>
          <div
            style={goBack ? { display: "none" } : null}
            className="black-zone"
            onClick={() => {
              setGoBack(true);
              setTimeout(() => {
                setShowKey(false);
                onHandleNavbar();
                setGoBack(false);
              }, "600");
            }}
          ></div>
          <div
            className="window"
            style={
              goBack
                ? {
                    maxHeight: "280px",
                    animation: "window_back 0.6s",
                    bottom: "-300px",
                  }
                : { maxHeight: "280px" }
            }
          >
            <div className="window_data">
              <img src={li} width={"100%"} alt="" />
              <div style={{ padding: "16px", height: "100%" }}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <X
                  className="close_x"
                    style={{ opacity: "12%" }}
                    onClick={() => {
                      setGoBack(true);
                      setTimeout(() => {
                        setShowKey(false);
                        onHandleNavbar();
                        setGoBack(false);
                      }, "600");
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "relative",
                    height: "100%",
                    textAlign: "center",
                  }}
                >
                  <div>
                    <Key />
                    <p className="wnd_header">Box Key</p>
                    <span className="wnd_text">
                    Use this key to open a box and get a new rank. Keys can be bought in the upgrade tab or earned by inviting friends.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="pg hm" style={margin}>
        <div className="home_pg">
          <div>
            <UserData onHandleShowRank={() => setShowRank(true)}/>
            <div
              style={{
                display: "flex",
                marginTop: "10px",
                justifyContent: "space-between",
              }}
            >
              <UserBalance />
              <div style={{ display: "flex", gap: "6px" }}>
                <UserTickets
                  onTickets={() => {
                    if (!goBack) {
                      setShowTickets(true);
                      onHandleNavbar();
                    }
                  }}
                />
                <UserKeys
                  onShowKey={() => {
                    if (!goBack) {
                      setShowKey(true);
                      onHandleNavbar();
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <Box
            onOpenBox={() => {
              if (keys < 1) {
                if (!error) {
                  onHandleKeys(true);
                  setTimeout(() => {
                    onHandleKeys(false);
                  }, "3000");
                } 
              } else {
                setOpenBox(true)
              }
            }}
          />
          <StartFarm
            onShowError={() => {
              if (!error) {
                onHandleTickets(true);
                setTimeout(() => {
                  onHandleTickets(false);
                }, "3000");
              }
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Home;
