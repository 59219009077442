import React, { useState } from "react";
import UserName from "../../components/username/UserName";
import UserTickets from "../../components/user-tickets/UserTickets";
import UserKeys from "../../components/user-keys/UserKeys";
import { ReactComponent as Invi } from "../../img/invites.svg";
import { ReactComponent as Copy } from "../../img/Copy.svg";
import Blocks from "../../components/blocks/Blocks";
import { useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ReactComponent as Key } from "../../img/Key.svg";
import { ReactComponent as Jetton } from "../../img/Jetton.svg";
import { ReactComponent as X } from "../../img/x.svg";
import li from "../../img/Bg light top.png";
import Friend from "../../components/friend/Friend";
import { useSelector } from "react-redux";
import axios from "axios";
import Spinner from "../../components/spinner/Spinner";

const Invite = ({ setShowAlert, alert, onHandleNavbar}) => {
  const [friends, setFriends] = useState([])
  const [showSpinner, setShowSpinner] = useState(true)
  const telegramId = useSelector(state => state.currentUser.telegramId)

  useEffect(() => {
    const getFriends = async () => {
      try {
        const { data } = await axios.get(`https://niseapp.lol/api/user/get-referrals/${telegramId}`)
        setFriends(data)
      } catch (error) {
        console.log(error)
      } finally {
        setTimeout(() => {
          setShowSpinner(false)
        }, 1000);
      }
    }
    getFriends()
  }, [])

  useEffect(() => {
    const tg = window.Telegram.WebApp;
    tg.ready(); // Initialize the Telegram Web App
  }, []);

  const shareContent = () => {
    window.Telegram.WebApp.openTelegramLink(
      `https://t.me/share/url?url=https://t.me/NiseAppBot?start=${telegramId}`
    );
  };
  const [showKey, setShowKey] = useState(false);
  const [showTickets, setShowTickets] = useState(false);
  const [goBack, setGoBack] = useState(false);

  return (
    <>
        {showTickets && (
        <>
          <div
            className="black-zone"
            style={goBack ? { display: "none" } : null}
            onClick={() => {
              setGoBack(true);
              setTimeout(() => {
                setShowTickets(false);
                onHandleNavbar();
                setGoBack(false);
              }, "600");
            }}
          ></div>
          <div
            className="window"
            style={
              goBack
                ? {
                    maxHeight: "280px",
                    animation: "window_back 0.6s",
                    bottom: "-300px",
                  }
                : { maxHeight: "280px" }
            }
          >
            <div className="window_data">
              <img src={li} width={"100%"} alt="" />
              <div style={{ padding: "16px", height: "100%" }}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <X
                  className="close_x"
                    style={{ opacity: "12%" }}
                    onClick={() => {
                      setGoBack(true);
                      setTimeout(() => {
                        setShowTickets(false);
                        onHandleNavbar();
                        setGoBack(false);
                      }, "600");
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "relative",
                    height: "100%",
                    textAlign: "center",
                  }}
                >
                  <div>
                    <Jetton />
                    <p className="wnd_header">Game pass</p>
                    <span className="wnd_text">
                    To get passes, click "Start Farm" on the main page. You can also increase the amount of mining in the upgrades tab.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {showKey && (
        <>
          <div
            style={goBack ? { display: "none" } : null}
            className="black-zone"
            onClick={() => {
              setGoBack(true);
              setTimeout(() => {
                setShowKey(false);
                onHandleNavbar();
                setGoBack(false);
              }, "600");
            }}
          ></div>
          <div
            className="window"
            style={
              goBack
                ? {
                    maxHeight: "280px",
                    animation: "window_back 0.6s",
                    bottom: "-300px",
                  }
                : { maxHeight: "280px" }
            }
          >
            <div className="window_data">
              <img src={li} width={"100%"} alt="" />
              <div style={{ padding: "16px", height: "100%" }}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <X
                  className="close_x"
                    style={{ opacity: "12%" }}
                    onClick={() => {
                      setGoBack(true);
                      setTimeout(() => {
                        setShowKey(false);
                        onHandleNavbar();
                        setGoBack(false);
                      }, "600");
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "relative",
                    height: "100%",
                    textAlign: "center",
                  }}
                >
                  <div>
                    <Key />
                    <p className="wnd_header">Box Key</p>
                    <span className="wnd_text">
                    Use this key to open a box and get a new rank. Keys can be bought in the upgrade tab or earned by inviting friends.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    <div
      className="pg"
      style={{
        marginBottom: "0px",
        display: "block",
        overFlowY: "auto",
        marginLeft: "0px",
        marginRight: "0px",
      }}
    >
      <div className="home_pg" style={{ justifyContent: "flex-start" }}>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <UserName />
          <div style={{ display: "flex", gap: "6px" }}>
            <UserTickets onTickets={() => {
                    if (!goBack) {
                      setShowTickets(true);
                      onHandleNavbar();
                    }
                  }}/>
            <UserKeys onShowKey={() => {
                    if (!goBack) {
                      setShowKey(true);
                      onHandleNavbar();
                    }
                  }}/>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className="ts_texts">
            <div className="tss">
              <Invi />
            </div>
            <p>Referall system</p>
            <span>
              You can invite friends through your referral link and get bonuses
            </span>
          </div>
        </div>
        <Blocks />
        <div style={{ display: "flex", width: "100%", gap: "8px" }}>
          <div className="start_farm" style={{ width: "100%" }}>
            <button onClick={shareContent}>Invite a friend</button>
          </div>
          <div className="start_farm" style={{ minWidth: "50px" }}>
            <CopyToClipboard text={`https://t.me/NiseAppBot?start=${telegramId}`}>
              <button
                onClick={() => {
                  if (!alert) {
                    setShowAlert(true);
                    setTimeout(() => setShowAlert(false), "3000");
                  }
                }}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Copy />
              </button>
            </CopyToClipboard>
          </div>
        </div>
        {
          showSpinner ? (
            <Spinner />
          ) : (
            <>
             <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <span
            style={{
              fontSize: "17px",
              color: "#fff",
              lineHeight: "22px",
              fontWeight: "590",
            }}
          >
            Referalls
          </span>
          <span
            style={{
              fontSize: "15px",
              color: "#fff",
              lineHeight: "20px",
              fontWeight: "510",
              opacity: "40%"
            }}
          >
            {friends.length}
          </span>
        </div>
        <div className="friends_list">
          {
            friends.map(el => {
              return <Friend rankType={el.rankType} rank={el.rank} userName={el.username} balance={el.balance} key={el.id}/>
            })
          }
        </div>
            </>
          )
        }
      </div>
    </div>
    </>
  );
};

export default Invite;
