import React from "react";
import { ReactComponent as RankType1 } from "../../img/rank_type_1.svg";
import { ReactComponent as RankType2 } from "../../img/rank_type_2.svg";
import { ReactComponent as RankType3 } from "../../img/rank_type_3.svg";
import { ReactComponent as RankType4 } from "../../img/rank_type_4.svg";
import el from "../../img/Ellipse 62.png";

const Friend = ({
  userName,
  balance,
  rank,
  rankType
}) => {

  function getRankType() {
    switch (rankType) {
      case 1:
        return <RankType1 />
      case 2:
        return <RankType2 />
      case 3:
        return <RankType3 />
      case 4:
        return <RankType4 />
      default:
        break;
    }
  }

  function rankColor() {
    switch (rankType) {
      case 1:
        return {color: "#C69C6D"}
      case 2:
        return {color: "#B2CCCC"}
      case 3:
        return {color: "#FED100"}
      case 4:
        return {color: "#6FDDE7"}
      default:
        break;
    }
  }
  return (
    <div className="user-data" style={{
        background: "var(--bg-secondary, #ffffff0f)"
    }}>
      <img className="ellipse" src={el} alt="" />
      <div className="uu">
        <div className="rang_small">
          <p style={rankColor()}>{rank}</p>
          {getRankType()}
        </div>
        <div>
          <p className="user_name">{userName.length > 12 ? `${userName.slice(0, 12)}...` : userName}</p>
          <p className="user_status">{balance.toLocaleString()} NISE</p>
        </div>
      </div>
      <div className="prft">
        <p>+500 NISE</p>
        <p>Profit</p>
      </div>
    </div>
  );
};

export default Friend;
