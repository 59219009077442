import React from "react";
import { ReactComponent as X2 } from "../../img/List Item/Left/Items card/x2.svg";
import { ReactComponent as Arw } from "../../img/ar.svg";
import { useSelector } from "react-redux";

const Upgrades = ({ open1, open2, open3, open4, onShowError }) => {

  const balance = useSelector(state => state.currentUser.balance)
  let upgrades = useSelector(state => state.currentUser.upgrades)

  if (upgrades == undefined) {
    upgrades = []
  }

  return (
    <div className="items_pg">
      <div style={upgrades.some(e => e.upgrade === 4) ? {pointerEvents: 'none'} : null} className="play" onClick={balance >= 5500 ? open4 :  onShowError}>
        <div>
          <div className="fw">
            <X2 />
          </div>
          <div className="flw_texts">
            <p>x2 to win </p>
            <span>The cost is 5,500 $NISE</span>
          </div>
        </div>
        {upgrades.some(e => e.upgrade === 4) ? (
        <div className="done-div">Active</div>
        ) : (
          <Arw />
        )}
      </div>
      <div style={upgrades.some(e => e.upgrade === 3) ? {pointerEvents: 'none'} : null} className="play" onClick={balance >= 5000 ? open3 :  onShowError}>
        <div>
          <div className="fw">
            <p className="p_ico">P</p>
          </div>
          <div className="flw_texts">
            <p>Buy Premium rank </p>
            <span>The cost is 5,000 $NISE</span>
          </div>  
        </div>
        {upgrades.some(e => e.upgrade === 3) ? (
        <div className="done-div">Active</div>
        ) : (
          <Arw />
        )}
      </div>
      <div className="play" style={upgrades.some(e => e.upgrade === 2) ? {pointerEvents: 'none'} : null}  onClick={balance >= 7777 ? open2 :  onShowError}>
        <div>
          <div className="fw">
            <p className="p_ico">X</p>
          </div>
          <div className="flw_texts">
            <p>Buy add-on X</p>
            <span>The cost is 7,777 $NISE</span>
          </div>  
        </div>
        {upgrades.some(e => e.upgrade === 2) ? (
        <div className="done-div">Active</div>
        ) : (
          <Arw />
        )}
      </div>
    </div>
  );
};

export default Upgrades;
