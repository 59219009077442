import React from "react";
import { useState } from "react";
import "./user-data.css";
import el from "../../img/Ellipse 62.png";
import { ReactComponent as RankType1 } from "../../img/rank_type_1.svg";
import { ReactComponent as RankType2 } from "../../img/rank_type_2.svg";
import { ReactComponent as RankType3 } from "../../img/rank_type_3.svg";
import { ReactComponent as RankType4 } from "../../img/rank_type_4.svg";
import { ReactComponent as Arrow } from "../../img/Icon.svg";
import { useSelector } from "react-redux";

const UserData = ({onHandleShowRank}) => {
  const [ranks, setRanks] = useState( [
    { id: 1, name: 'Jonokami' },
    { id: 2, name: 'Chonin' },
    { id: 3, name: 'Jonzin' },
    { id: 4, name: 'Keninin' },
    { id: 5, name: 'Kanzaki' },
    { id: 6, name: 'Choseinin' },
    { id: 7, name: 'Kusa' },
    { id: 8, name: 'Suigyonin' },
    { id: 9, name: 'Kitanin' },
    { id: 10, name: 'Kuchinami' },
    { id: 11, name: 'Ujonin' },
    { id: 12, name: 'Kagenin' },
    { id: 13, name: 'Nagarenin' },
    { id: 14, name: 'Yokainin' },
    { id: 15, name: 'Seron' },
    { id: 16, name: 'Bakenanin' },
    { id: 17, name: 'Rainin' },
    { id: 18, name: 'Shinobiwuno' },
    { id: 19, name: 'Mizunin' },
    { id: 20, name: 'Tenin' },
    { id: 21, name: 'Irukanin' },
    { id: 22, name: 'Sorunin' },
    { id: 23, name: 'Yoginin' },
    { id: 24, name: 'Kuronin' },
    { id: 25, name: 'Moronin' },
    { id: 26, name: 'Kakinenin' },
    { id: 27, name: 'Hiyonin' },
    { id: 28, name: 'Satsuzonin' },
    { id: 29, name: 'Narentin' },
    { id: 30, name: 'Meinin' }
  ])
  const name = useSelector(state => state.currentUser.name)
  const rank = useSelector(state => state.currentUser.rank)
  const rankType = useSelector(state => state.currentUser.rankType)

  function getRankType() {
    switch (rankType) {
      case 1:
        return <RankType1 />
      case 2:
        return <RankType2 />
      case 3:
        return <RankType3 />
      case 4:
        return <RankType4 />
      default:
        break;
    }
  }

  function rankColor() {
    switch (rankType) {
      case 1:
        return {color: "#C69C6D"}
      case 2:
        return {color: "#B2CCCC"}
      case 3:
        return {color: "#FED100"}
      case 4:
        return {color: "#6FDDE7"}
      default:
        break;
    }
  }

  return (
    <div className="user-data" onClick={onHandleShowRank}>
      <img className="ellipse" src={el} alt="" loading="lazy"/>
      <div className="uu">
        <div className="rang">
          <p style={rankColor()}>{rank}</p>
          {getRankType()}
        </div>
        <div>
          <p className="user_name">{name.length > 12 ? `${name.slice(0, 12)}...` : name}</p>
          <p className="user_status">{ranks.find(r => r.id === rank).name}</p>
        </div>
      </div>
      <Arrow />
    </div>
  );
};

export default UserData;
